<template>
    <div class="tgBotCreatePane">
        <div class="tgBotBlock" v-if="step == 0">
            <h2 class="labelBig">Здравствуй дорогой друг</h2>
            <h3 class="labelDesc">давай создадим с тобой Telegram bot</h3>
            <h4 class="labelMini">Тебе понадобится: </h4>
            <ul class="navi navi-hover">
                <li><i class="flaticon2-check-mark"></i> Установленный Телеграм на ПК или Телефоне</li>
                <li><i class="flaticon2-check-mark"></i> Руки</li>
                <li><i class="flaticon2-check-mark"></i> Чашечка кофе, в тяжелых случаях сигарета</li>
            </ul>
            <button class="btn btn-primary btnActive" @click="nextStep(1)">Поехали</button>
        </div>
        <div class="tgBotBlock" v-if="step == 1">
            <h2 class="labelBig">Превосходно</h2>
            <h3 class="labelDesc">рад что ты не испугался</h3>
            <h4 class="labelMini">Дай же мне нормальное имя: </h4>
            <input v-model="name" type="text" @keyup="mimikaAssistent()" @change="mimikaAssistent(true)">
            <button class="btn btn-success btnActive" @click="nextStep(2)"><i class="flaticon2-checkmark"></i> <strong>{{ name }}</strong></button>
        </div>
        <div class="tgBotBlock" v-if="step == 2">
            <h2 class="labelBig">Я, {{ name }}</h2>
            <h3 class="labelDesc">повелеваю тебе</h3>
            <h4 class="labelMini">Придумай мне паспортное имя, только маленькими и латиницей: </h4>
            <input v-model="botName" type="text" @keyup="mimikaAssistent()" @change="mimikaAssistent(true)">{{ suffixBot }}
            <button class="btn btn-danger btnActive" @click="nextStep(3)"><i class="flaticon2-checkmark"></i> <strong>{{ botName }}</strong></button>
        </div>
        <div class="tgBotBlock" v-if="step == 3">
            <h2 class="labelBig">Отлично мой друг</h2>
            <h3 class="labelDesc">мы окончили подготовительные процедуры</h3>
            <h4 class="labelMini">Я {{ name }} в поиске @{{ botName }}{{ suffixBot }}</h4>
            <p>Теперь мы можем приступить регистрации</p>
            <button class="btn btn-primary btnActive" @click="nextStep(0)"><i class="flaticon2-checkmark"></i> <strong>Хочу приступить</strong></button>
        </div>
        <i class="assisent fas" :class="classesAssistantSet"></i>
    </div>
    <!-- <textarea>{{ name }}</textarea> -->
</template>

<script>
export default {
    props: {
        nameDomain: {
            type: String,
            default: ''
        },
        nameSystem: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            token: '',
            name: this.nameSystem,
            botName: 'site_' + this.nameDomain,
            suffixBot: '_leads_bot',
            token: '',

            step: 0,
            classesAssistantSet: 'fa-headset',
            classesAssistant: {
                ok: 'fa-grin-wink',
                gdun: 'fa-headset',
                error: 'fa-tired',
                what: 'fa-surprise',
                no: 'fa-meh-rolling-eyes',
            }
        }
    },
    mounted(){
        this.token = $('meta[name="csrf-token"]').attr('content');
    },
    computed: {
        // classAssistant: function(){ return this.classesAssistant.gdun; }
    },
    methods: {
        nextStep(step){
            let _this = this;
            _this.step = step;
            _this.classesAssistantSet = this.classesAssistant.what;
        },
        mimikaAssistent(def = false){
            let _this = this;
            let r = Math.random();
            if(def){
                _this.classesAssistantSet = this.classesAssistant.gdun;
            } else {
                if(r < 0.3){
                    _this.classesAssistantSet = this.classesAssistant.no;
                } else if(r > 0.7){
                    _this.classesAssistantSet = this.classesAssistant.ok;
                } else {
                    _this.classesAssistantSet = this.classesAssistant.what;
                }
            }
        },
    },
}
</script>
