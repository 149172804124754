import { createApp, ref } from 'vue';
import TgBotCreate from './Components/TgBotCreate.vue';
import NotificationComponent from './Components/Notification.vue';

const app = createApp({});

const clickOutsideDirective = {
    // добавляем эвент для тыка вне компонента
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
        // нужно удалять эвенты, берегите ОЗУ клиентов
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
};
app.directive('click-outside', clickOutsideDirective);
// use v-click-outside="yourMethod"

app.component('vue-tg-bot-create', TgBotCreate);
app.component('vue-notification-component', NotificationComponent);
// app.component('color-product-count-list', ColorProductCount);

app.mount('#app-vue-admin');

